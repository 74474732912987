import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import downloadArrow from "../../../assets/img/download-arrow.png";
import ReactToPdf from "react-to-pdf";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar";
import { Button, CardContent, Skeleton } from "@mui/material";
import ChartArea from "../../../components/ChartArea";
import {
  getAiPerformanceRate,
  getBotResponseTime,
  getEscalationRate,
  getRetentionRate,
} from "../../../slices/analytics";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { toFixedTwo } from "../../../utilities/toFixedTwo";

function AiStats() {
  const { t } = useTranslation();
  moment.locale(localStorage.getItem("i18nextLng"));
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [14, 15],
  };
  const {
    retentionRate,
    totalRetentionRate,
    statusRetentionRate,
    statusAiPerformanceRate,
    aiPerformanceRate,
    totalAiSuccessRate,
    totalAiFailureRate,
    statusEscalationRate,
    escalationRate,
    totalEscalationRate,
    averageBotResponseTime,
    statusBotResponseTime,
    totalAverageBotResponseTime,
  } = useSelector((state) => state.analytics);

  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(new Date());

  useEffect(() => {
    !Array.isArray(user) &&
      (statusRetentionRate !== "success" &&
        dispatch(getRetentionRate({ websiteID: user?.websiteID, year, month })),
      statusAiPerformanceRate !== "success" &&
        dispatch(
          getAiPerformanceRate({ websiteID: user?.websiteID, year, month })
        ),
      statusEscalationRate !== "success" &&
        dispatch(
          getEscalationRate({ websiteID: user?.websiteID, year, month })
        ),
      statusBotResponseTime !== "success" &&
        dispatch(
          getBotResponseTime({ websiteID: user?.websiteID, year, month })
        ));
  }, [user, year, month]);

  return (
    <div className="analytics-container-ai">
      <div className="title--analytics--page-ai">
        <div>
          <p className="analytics--p-ai">
            {t("Analytics").replace(" ", "\xa0")}
          </p>
        </div>
        <div className="btns-container-analytics-ai">
          <div>
            {" "}
            <Button
              disabled={month === null}
              onClick={() => setMonth(null)}
              className="reset-month-btn"
            >
              {t("Reset month")}
            </Button>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <DatePicker
                  views={["month"]}
                  disableFuture
                  slotProps={{
                    calendarHeader: {
                      sx: { display: "none" },
                    },
                  }}
                  value={month}
                  onChange={(newValue) => setMonth(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  views={["year"]}
                  disableFuture
                  minDate={new Date("2024")}
                  value={year}
                  onChange={(newValue) => setYear(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </LocalizationProvider>
          </div>

          <ReactToPdf
            targetRef={ref}
            filename="div-blue.pdf"
            options={options}
            x={1}
            y={1}
            scale={0.7}
          >
            {({ toPdf }) => (
              <div onClick={toPdf} className="download-btn-ai">
                <div>
                  <LazyLoadImage
                    src={downloadArrow}
                    alt="arrow"
                    className="download-arrow"
                  />
                </div>

                <button className="btn-report-ai">
                  {t("Download Report")}
                </button>
              </div>
            )}
          </ReactToPdf>
        </div>
      </div>
      <div ref={ref}>
        {" "}
        <div className="stat-wrapper-ai">
          {["idle", "loading"].includes(statusAiPerformanceRate) ? (
            <div style={{ padding: "0 12px", width: "100%" }}>
              <Skeleton
                width={"100%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            </div>
          ) : (
            <>
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai">
                    {t("Taux de réussite de performance de l'IA")}:{" "}
                    <span>{toFixedTwo(totalAiSuccessRate)}%</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    aiSuccessPerformanceRate={aiPerformanceRate}
                  />
                </CardContent>
              </div>
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai">
                    {t("Taux d'échec des performances de l'IA")} :{" "}
                    <span>{toFixedTwo(totalAiFailureRate)}%</span>
                  </h2>
                </div>
                <CardContent style={{ padding: 0 }}>
                  <ChartArea
                    month={month}
                    aiFailurePerformanceRate={aiPerformanceRate}
                  />
                </CardContent>
              </div>
            </>
          )}
        </div>
        <div className="stat-wrapper-ai">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {" "}
            {["idle", "loading"].includes(statusRetentionRate) ? (
              <Skeleton
                width={"49%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />
            ) : (
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai" style={{ paddingLeft: 0 }}>
                    {t("Taux de rétention")}
                  </h2>
                </div>
                <div className="stat-advanced-wrapper">
                  <div className="stat-advanced-description">
                    <span>{toFixedTwo(totalRetentionRate)} sec</span>
                  </div>
                  <CardContent style={{ padding: 0, flex: 1 }}>
                    <ChartArea retentionRate={retentionRate} month={month} />
                  </CardContent>
                </div>
              </div>
            )}
            {["idle", "loading"].includes(statusEscalationRate) ? (
              <Skeleton width={"49%"} />
            ) : (
              <div className="stat-container-ai">
                <div>
                  <h2 className="stat-title-ai" style={{ paddingLeft: 0 }}>
                    {t("Taux d'escalade")}
                  </h2>
                </div>
                <div className="stat-advanced-wrapper">
                  <div className="stat-advanced-description">
                    <span>{toFixedTwo(totalEscalationRate)}%</span>
                  </div>
                  <CardContent style={{ padding: 0, flex: 1 }}>
                    <ChartArea month={month} escalationRate={escalationRate} />
                  </CardContent>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="stat-wrapper-ai" style={{ flexWrap: "nowrap" }}>
          {["idle", "loading"].includes(statusBotResponseTime) ? (
            <div style={{ width: "50%", padding: "0 12px" }}>
              <Skeleton
                width={"100%"}
                sx={{ minHeight: "400px", height: "100%" }}
              />{" "}
            </div>
          ) : (
            <div className="stat-container-ai">
              <div>
                <h2 className="stat-title-ai" style={{ paddingLeft: 0 }}>
                  {t("Temps de réponse moyen du robot")}
                </h2>
              </div>
              <div className="stat-advanced-wrapper">
                <div className="stat-advanced-description">
                  <span>
                    {toFixedTwo(totalAverageBotResponseTime)} {t("sec")}
                  </span>
                </div>
                <CardContent style={{ padding: 0, flex: 1 }}>
                  <ChartArea
                    averageBotResponseTime={averageBotResponseTime}
                    month={month}
                  />
                </CardContent>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AiStats;
